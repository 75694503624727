.navbar {
    width: 100%;
    height: 27vh;
    z-index: 100;
    position: fixed;
    transition: all 0.25s linear;
    background-color: transparent;
    display: flex;
    padding: 0 5rem;
    align-items: center;
    justify-content: space-between;
}

.sticky {
    background-color: #74aa9cE6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.buttonContainer {
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > button {
        width: 6.5rem;
        height: 2.5rem;
        cursor: pointer;
        font-weight: 600;
        border-radius: 5px;
        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .historyButton {
        border: none;
        color: white;
        background-color: transparent;
    }
}