.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 4rem;
    gap: 2rem;
    > h2 {
        font-size: 2rem;
        font-weight: bold;
    }
    > form {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
    }
}