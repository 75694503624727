.body {
    height: calc(100vh + 14rem + 6rem);
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    justify-content: space-between;
    .headerOfBody {
        width: 100%;
        height: 14rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        > div {
            width: 100%;
            height: 100%;
            padding: 2rem;
            display: flex;
            align-items: center;
            flex-flow: column nowrap;
            justify-content: space-between;
            border: 1px solid #e3e9ed;
            > div {
                width: 4rem;
                aspect-ratio: 1;
                background-size: cover;
                background-repeat: no-repeat;
            }
            .speedometer {
                background-image: url("../../assets/iconsColored/speedometer.svg");
            }
            .service {
                background-image: url("../../assets/iconsColored/service.svg");
            }
            .taskList {
                background-image: url("../../assets/iconsColored/task-list.svg");
            }
            .badge {
                background-image: url("../../assets/iconsColored/badge.svg");
            }
            > h6 {
                color: #111111;
                font-size: 1rem;
                font-weight: bold;
                display: flex;
                align-items: center;
            }
            > p {
                color: #444;
                font-size: 1rem;
                text-align: center;
                line-height: 1.5rem;
                display: flex;
                align-items: center;
            }
        }
    }
    .contentsOfBody {
        width: 100%;
        height: 0;
        flex-grow: 1;
        padding: 3rem 5.5rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(4, max-content);
        row-gap: 3rem;
        column-gap: 2rem;
        > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            flex-flow: column nowrap;
            > h2 {
                font-size: 2rem;
                font-weight: bold;
                color: #111111;
                line-height: 2rem;
                text-align: right;
            }
            > h5 {
                font-size: 1.5rem;
                font-weight: bold;
                color: #111111;
                line-height: 2rem;
                text-align: right;
            }
            > p {
                font-size: 1rem;
                color: #444;
                line-height: 2rem;
                text-align: right;
            }
            .separator {
                width: 350px;
                height: 1px;
                position: relative;
                background-color: #e6e6e6;
                > div {
                    width: 200px;
                    height: 1px;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    background-color: #0635c9;
                    transform: translateX(-50%);
                    transition: all 0.5s linear;
                }
                > span {
                    z-index: 2;
                    width: 10px;
                    aspect-ratio: 1;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    background-color: #fff;
                    border: 1px solid #0635c9;
                    border-radius: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
        }
    }
    .footerOfBody {
        width: 100%;
        height: 6rem;
        padding: 1.5rem;
        background-color: #2a72aae6;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        > div {
            height: 100%;
        }
        .submitEmail {
            width: 100%;
            > button {
                cursor: pointer;
                width: 10.5rem;
                height: 100%;
                direction: rtl;
                font-size: 1rem;
                font-weight: 600;
                border: 1px solid #e3e9ed;
                border-radius: 0 5px 5px 0;
                color: #444;
                background-color: #f2f2f2;
                position: relative;
            }
            > input {
                width: 17rem;
                height: 100%;
                direction: rtl;
                padding: 1rem;
                font-size: 1rem;
                border: 1px solid #e3e9ed;
                border-radius: 5px 0 0 5px;
            }
        }
        .joinUs {
            width: 100%;
            color: white;
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 0.5fr 7fr;
            direction: ltr;
            gap: 1rem;
            .joinUsIcon {
                grid-row: 1/-1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}