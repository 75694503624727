.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	.label {
		padding: 0 0.5rem;
		border-radius: 0.4rem;
		margin-bottom: 0.5rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.inputContainer {
		width: 100%;
		height: 100%;
		display: grid;
		position: relative;
		align-items: center;
		.input {
			width: 100%;
			height: 2.5rem;
			overflow: hidden;
			position: relative;
			border-radius: 8px;
			white-space: nowrap;
			box-sizing: border-box;
			padding: 0.75rem 0.5rem;
			text-overflow: ellipsis;
			border: 2px #ececec solid;
			color: #33313C;
			&:hover,
			&:active {
				border: 1px #bebcbd solid;
			}
			&:disabled {
				border: 2px #ececec solid;
				background-color: #E4E4E7;
				color: #000;
			}
			&::placeholder {
				color: #d3d2d2;
				font-weight: 400;
				font-size: 1rem;
			}
		}
		.inputChild {
			display: flex;
			position: absolute;
			align-items: center;
			justify-self: flex-end;
			justify-content: center;
			margin: 1px 1.5rem;
		}
	}
}

.labelRequired::after {
	content: "*";
	color: #CC3333;
	font-size: 1rem;
}