.overlay {
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background: #7e7e7e7c;
	z-index: 100;
}

.hide {
	display: none;
}

.bodyContainer {
	background-color: white;
	border-radius: 8px;
	overflow: hidden;
	padding: 0.5em;
}
