@font-face {
	font-family: "Nunito Sans";
	src: url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap");
}

* {
	margin: 0;
	padding: 0;
	outline: none;
	box-sizing: border-box;
}

*:focus {
    outline: none;
}

button {
	border: 0;
}

body {
	display: flex;
	direction: rtl;
	font-size: 1rem;
	font-weight: 400;
	font-family: Nunito Sans, sans-serif;
	line-height: 1rem;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.app {
	width: calc(100vw - 15px);
}
